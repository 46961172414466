import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';
import pic6 from '../assets/images/pic06.jpg';
import pic7 from '../assets/images/pic07.jpg';
import pic8 from '../assets/images/pic08.jpg';
import pic9 from '../assets/images/pic09.jpg';
import pic10 from '../assets/images/pic10.jpg';
const IndexPage = () => <Layout>
	<article id="main">
		<header>
			<h2>Gallery</h2>
			<p>Aliquam ut ex ut interdum donec amet imperdiet eleifend</p>
		</header>
		<section className="wrapper style5">
			<div className="inner">

				<section>
					<div className="box alt">
						<div className="row gtr-50 gtr-uniform">
							<div className="col-4"><span className="image fit"><img src={pic1} alt="" /></span></div>
							<div className="col-4"><span className="image fit"><img src={pic2} alt="" /></span></div>
							<div className="col-4"><span className="image fit"><img src={pic3} alt="" /></span></div>
							<div className="col-4"><span className="image fit"><img src={pic4} alt="" /></span></div>
							<div className="col-4"><span className="image fit"><img src={pic5} alt="" /></span></div>
							<div className="col-4"><span className="image fit"><img src={pic6} alt="" /></span></div>
							<div className="col-4"><span className="image fit"><img src={pic7} alt="" /></span></div>
							<div className="col-4"><span className="image fit"><img src={pic8} alt="" /></span></div>
							<div className="col-4"><span className="image fit"><img src={pic9} alt="" /></span></div>
						</div>
					</div>
				</section>

			</div>
		</section>
	</article>

</Layout>;

export default IndexPage;
